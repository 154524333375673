WiseMetering.Views.ERedesIntegrationFiles = WiseMetering.Views.TableIndex.extend({
    createTable: function(data = []) {
        const collection = new WiseMetering.Collection(data);

        this.table = new WiseMetering.Views.Table({
            id: 'files',
            collection,
            modelSerializer: model => {
                return {
                    id: model.id,
                    name: model.get('name'),
                    from: model.get('file_data_from'),
                    to: model.get('file_data_to'),
                    copiedAt: WiseMetering.utils.formatDate(model.get('file_copy_at')),
                    receivedAt: WiseMetering.utils.formatDate(model.get('file_received_at')),
                    dataGenerateAt: model.get('file_data_generated_at'),
                    processed: i18next.t(`common.${model.get('processed') ? 'yes' : 'no'}`),
                    file_size: model.get('file_size_human'),
                };
            },
            columns: {
                name: i18next.t('common.name'),
                from: i18next.t('common.from'),
                to: i18next.t('common.to'),
                receivedAt: i18next.t('integrations.received_at'),
                copiedAt: i18next.t('integrations.copied_at'),
                dataGenerateAt: i18next.t('integrations.generated_at'),
                processed: i18next.t('integrations.processed'),
                file_size: i18next.t('integrations.file_size'),
            },
            toolbar_actions: {

                show: {
                    label: i18next.t('ui.actions.show'),
                    view_call: model => window.open(model.get('file_url'))
                }
            }
        });

        $(this.body.el).html(this.table.el);
    },

    onClose: function() {
        if (this.table && this.table.remove && typeof (this.table.remove) === 'function') {
            this.table.remove();
        }
    },

    onShow: function() {
        this.showLoader();

        $.ajax({
            url: `/integrations/${this.model.id}/files`,
            type: 'GET',
            contentType: 'application/json',
            success: function(data) {
                this.createTable(data);
            }.bind(this),
            error: function(err) {
                WiseMetering.layout.showTipper('error', 'unable to fetch files');
            }.bind(this)
        });
    }
});
